<template>
    <div>
        <div v-if="!fixedLanguages" class="w-50 pr-1 mb-4">
            <LabelForm>{{ $t("shared.localized-content.language") }}</LabelForm>
            <div class="flex">
                <select v-model="selectedLanguage" class="w-full border-grey-light input-modal">
                    <option :key="language.acronym" :value="language.acronym" v-for="language in missignLanguages">
                        {{ language.name }}
                    </option>
                </select>
                <button :disabled="selectedLanguage === null" class="ml-3 sk-btn sk-btn-primary rounded-lg" @click.stop="addLanguageFromButton">{{ $t('shared.add') }}</button>
            </div>
        </div>

        <div class="flex flex-wrap">
            <div v-for="(contentLanguage, index) in internalLanguages" :key="contentLanguage.language"
                :class="`w-full lg:w-${gridColumns}/12  relative mt-6 lg:mr-6 p-6 border border-grey rounded`"
            >
                <div class="flex justify-between items-center">
                    <h5 class="mb-0">{{ getLanguageName(contentLanguage.language) }}</h5>

                    <button v-if="!fixedLanguages && fixedLanguage !== contentLanguage.language" class="ml-4 sk-btn sk-btn-small btn-border sk-btn-primary rounded-lg"
                        @click.stop="deleteLanguage(contentLanguage.language)"
                    >
                        <feather-icon icon="TrashIcon" class="align-middle" svgClasses="text-primary w-4" />
                    </button>
                </div>

                <div v-for="field in fieldsDefinition" :key="field.name">

                    <vs-input :label="field.label" v-if="field.type === 'text'" class="w-full border-grey-light"
                        v-model="internalLanguages[index][field.name]" @input="sync" />

                    <div v-if="field.type === 'textarea'" 
                        class="vs-component vs-con-input-label vs-input w-full border-grey-light vs-input-primary"
                    >
                        <label for="" class="vs-input--label">{{ field.label }}</label>
                        <div class="vs-con-input">
                            <textarea class="w-full h-32 rounded resize-none border-grey-light p-2"
                                v-model="internalLanguages[index][field.name]" 
                                @input="sync" 
                            />
                        </div>
                    </div>

                    <div v-if="field.type === 'rich_textarea'" 
                        class="vs-component vs-con-input-label vs-input w-full border-grey-light vs-input-primary"
                    >
                        <label for="" class="vs-input--label">{{ field.label }}</label>
                        <div class="vs-con-input">
                            <editor
                                v-model="internalLanguages[index][field.name]" 
                                :api-key="tinyMCEapikey"
                                :init="editorConfig"
                                @input="sync"
                            />
                        </div>
                    </div>

                    <Tags v-if="field.type === 'tags'" v-model="internalLanguages[index][field.name]" :label="field.label" />

                    <div v-if="field.type === 'image'" class="mb-5">
                        <label for="" class="vs-input--label">{{ field.label }}</label>
                        <ImageFormForm
                            v-model="internalLanguages[index][field.name]"
                            :width="field.width || null"
                            :height="field.height || null"
                            :validation-weight="field.weight || null"
                            :validation-message="field.validationMessage || null"
                            :constraints-text="field.constraintsText || null"
                            :valid-sizes="field.validSizes || []"
                        />
                    </div>

                    <CharCounter v-if="field.maxLength"
                        :maxLength="field.maxLength"
                        :item="internalLanguages[index][field.name] || ''"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import Tags from '@/modules/Shared/Components/form/Tags'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import CharCounter from '@/modules/Shared/Components/form/CharCounter'
import ImageFormForm from "@/modules/Shared/Components/form/ImageForm.vue";

export default {
    name: 'LocalizedContent',
    components: {
        ImageFormForm,
        LabelForm,
        CharCounter,
        Tags,
        'editor': Editor,
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        fieldsDefinition: {
            type: Array,
            default: () => []
        },
        languages: {
            type: Array,
            default: () => []
        },
        fixedLanguages: {
            type: Boolean,
            default: false
        },
        fixedLanguage: {
            type: String
        },
        columns: {
            type: Number,
            default: 2
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function () {
                this.internalLanguages = this.value
            }
        },
        fixedLanguage: {
            immediate: true,
            handler: function () {
                this.sortLanguages()
            }
        }
    },
    computed: {
        ...mapGetters(['userSpotlioLanguages']),
        missignLanguages() {
            let currentLangAcronyms = this.internalLanguages.map((l) => l.language)
            const languages = this.userSpotlioLanguages.filter(lang => !currentLangAcronyms.includes(lang.acronym))

            return this.languages.length === 0
                ? languages
                : languages.filter(lang => this.languages.includes(lang.acronym))
        },
        gridColumns() {
            return (12 / this.columns) - 1
        }
    },
    data() {
        return {
            selectedLanguage: null,
            internalLanguages: [],
            tinyMCEapikey: process.env.VUE_APP_TINY_MCE_API_KEY,
            editorConfig: {
                height: 500,
                width: '100%',
                menubar: false,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table contextmenu paste"
                ],
                toolbar:
                    'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
            },
        }
    },
    methods: {
        createEmptyLanguage(selectedLanguage) {
            let language = { language: selectedLanguage }
            this.fieldsDefinition.map((definition) => language[definition.name] = null)
            return language
        },
        addLanguageFromButton() {
            this.addLanguage(this.selectedLanguage)
        },
        addLanguage(language) {
            if (this.internalLanguages.find((l) => l.language === language)) return
            this.internalLanguages.push(this.createEmptyLanguage(language))
            this.selectedLanguage = null
            this.sync()
        },
        deleteLanguage(acronym) {
            let index = this.internalLanguages.findIndex((l) => l.language === acronym)
            this.internalLanguages.splice(index, 1)
            this.sync()
        },
        sortLanguages() {
            if (this.fixedLanguage) {
                this.internalLanguages.sort((a, b) => {
                    if (a.language === this.fixedLanguage) return -1
                    if (b.language === this.fixedLanguage) return 1
                    return 0
                })
            }
        },
        getLanguageName(acronymo) {
            return this.userSpotlioLanguages.find(l => l.acronym === acronymo).name
        },
        sync() {
            this.$emit('input', this.internalLanguages)
        },
    }
}
</script>
